import { v4 as uuidv4 } from 'uuid';

export function getUserId(): string {
    const uid = window.localStorage.getItem("UID")
    if (uid) {
        return uid
    }
    const id = uuidv4()
    window.localStorage.setItem("UID", id)
    return id
}
