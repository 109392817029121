import React, { useState } from "react";

import { Button, Form, Grid, Input, theme, Typography } from "antd";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHttp } from "../App";
import { HttpFetch } from "../fetch";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export function AuthForm(props: { onLogin?: () => any }) {
  const { token } = useToken();
  const screens = useBreakpoint();
  const httpFetch = useHttp()
  const [mode, setMode] = useState("login" as "login" | "signup" | "reset")
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    if(mode === "signup") {
      registerUser(httpFetch, values['username'], values['password'], values['email'], props.onLogin)
    } else if (mode === "login"){
      loginUser(httpFetch, values['password'], values['email'], props.onLogin)
    }
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      backgroundColor: token.colorBgContainer,
      display: "flex",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <img src="/logo512.png" style={{ width: "30%" }} alt="logo"/>

          <Title style={styles.title}>{t(mode === "login" ? "Log in" : (mode === "signup" ? "Sign up" : "Reset password"))}</Title>
        </div>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          {
            mode !== "signup" ? null :
              <Form.Item
                name="username"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: t("Please input your Username"),
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder={t("Username")}
                />
              </Form.Item>
          }
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder={t("Email")}
            />
          </Form.Item>
          {mode === "reset" ? null :
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder={t("Password")}
              />
            </Form.Item>
          }

          {/* <Form.Item>
            {
              mode !== "login" ? null :
                <a onClick={() => setMode("reset")}>
                  Forgot password?
                </a>
            }
          </Form.Item> */}
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block={true} type="primary" htmlType="submit">
              {t(mode === "login" ? "Log in" : (mode === "reset" ? "Reset password" : "Sign up"))}
            </Button>
            {
              mode !== "signup" ?
                <div>
                  <Text style={styles.text}>{t("Don't have an account?")}</Text>{" "}
                  <Link onClick={() => setMode("signup")}>{t("Sign up")}</Link>
                </div>
                :
                <div>
                  <Text style={styles.text}>{t("Already have an account?")}</Text>{" "}
                  <Link onClick={() => setMode("login")}>{t("Log in")}</Link>
                </div>
            }
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}

function registerUser(httpFetch: HttpFetch, username: string, password: string, email: string, onLogin?: () => any) {
  return httpFetch(`/api/auth/register`, {
    method: "POST",
    body: JSON.stringify({ username, email, password }),
  }).then((res) => {
    return res.json().then((d) => {
      if(onLogin) onLogin()
      return{
        accessToken: d['accessToken']
      }
    })
  })
}

function loginUser(httpFetch: HttpFetch, password: string, email: string, onLogin?: () => any) {
  return httpFetch(`/api/auth/login`, {
    method: "POST",
    body: JSON.stringify({  email, password }),
  }).then((res) => {
    return res.json().then((d) => {
      if(onLogin) onLogin()
      return{
        accessToken: d['accessToken']
      }
    })
  })
}
