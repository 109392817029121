import { Form, Input, Select, Space } from "antd";
import { ReactNode, useContext, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { BoardGameSearchEntry, UserBoardGameEntry } from "../api";
import { CurrentUserContext, useHttp } from "../App";

export function GameSelect(props: { onChange: (e?: BoardGameSearchEntry) => any, onNoteChange: (note: string) => any }) {
    const {currentUser} = useContext(CurrentUserContext)
    const [options, setOptions] = useState({ term: "", values: [], loading: false });
    const [note, setNote] = useState("")
    const httpFetch = useHttp()

    const serverSearch = useDebouncedCallback((value: string) => {
        setOptions({ values: options.values, term: value, loading: true })
        if (!value) {
            return
        }
        const url = new URL("/api/games", document.location.origin)
        url.searchParams.append("search", value)
        httpFetch(url).then((resp) => resp.json()).then(data => {
            const r = data.reduce((prev: any[], curr: BoardGameSearchEntry) => {
                if (curr.name) {
                    prev.push({ label: curr.name + (curr.publicationYear ? ` (${curr.publicationYear})` : ""), value: curr.id, game: curr })
                }
                return prev
            }, [])
            setOptions((prev) => {
                if(prev.term !== value) {
                    return prev
                }
                return {
                    loading: false,
                    term: value,
                    values: r
                }
            })
        })
    }, 500, { maxWait: 1000 })
    const onSearch = (value: string) => {
        if (value) {
            serverSearch(value)
        } else {
            setOptions({ term: "", loading: false, values: [] })
        }
    }
    const onChange = (v: any, o: any) => {
        if (props.onChange && o?.game) {
            setNote(o?.game?.note || "")
            props.onNoteChange(o?.game?.note || "")
            props.onChange(o.game)
        }
    }
    const onClear = () => {
        if (props.onChange) {
            setNote("")
            props.onNoteChange("")
            props.onChange(undefined)
        }
    }
    const onNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("TT")
        setNote(e.target.value)
        props.onNoteChange(e.target.value)
    }

    return (
        <Form.Item>
            <Space.Compact>
                <Form.Item noStyle>
                    <Select
                        style={{ width: 300 }}
                        filterOption={true}
                        optionFilterProp="label"
                        allowClear={true}
                        showSearch={true}
                        options={prepareOptions(options.values, currentUser?.games || [], options.term)}
                        loading={options.loading}
                        onSearch={onSearch}
                        onChange={onChange}
                        onClear={onClear}
                        optionRender={optionRender}
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Input style={{ width: '35%' }} placeholder="notatka" value={note} onChange={onNoteChange}/>
                </Form.Item>
            </Space.Compact>
        </Form.Item>
    );
}

function prepareOptions(opts: any[], userOpts: any[], term: string):any[] {
    userOpts = userOpts.filter((val: UserBoardGameEntry) => {
        return term === "" || val.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1
    })
    .sort((a: UserBoardGameEntry, b: UserBoardGameEntry) => {return a.name.localeCompare(b.name)})
    .reduce((prev: any[], curr: UserBoardGameEntry) => {
        if (curr.name) {
            prev.push({ label: curr.name + (curr.publicationYear ? ` (${curr.publicationYear})` : ""), value: curr.id, game: curr })
        }
        return prev
    }, [])
    return [].concat(userOpts as any, opts.filter((el) => {
        return userOpts.map((e) => e.value).indexOf(el.value) === -1
    }) as any)
}

function optionRender(option: any): ReactNode {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {option?.data?.game?.thumbnail ? <div style={{ width: 32, height: 32, flexShrink: 0, justifyContent: "center", display: "flex" }}><img src={option.data.game.thumbnail} style={{ maxWidth: 32, maxHeight: 32, margin: "auto" }} alt="" /></div> : null}
            <div style={{ display: "inline-flex" }}>&nbsp;{option.label}</div>
        </div>
    )
}
