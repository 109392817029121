import { getUserId } from "./user"
import { jwtDecode } from "jwt-decode";

let token: string|null|undefined = undefined
let refreshPromise: Promise<Response>|null = null;

export type HttpFetch = (url: URL | string, init?: RequestInit) => Promise<Response>

export async function httpFetch(url: URL | string, init?: RequestInit): Promise<Response>{
    const i = init || {}
    const headers: Record<string, string> = {}
    headers['X-User'] = getUserId()
    if ((i.method || "GET").toLowerCase() !== "GET") {
        headers['Content-Type']= 'application/json'
    }

    if (token!==null && !tokenIsValid(token)) {
        if (refreshPromise === null){
            refreshPromise = fetch("/api/auth/refresh", {method: "POST"})
        }
        await refreshPromise?.then((response) => {
            if(response.ok && response.headers.has('x-access-token')) {
                token = response.headers.get('x-access-token')
            } else {
                token = null
            }
            refreshPromise = null
        })
    }

    if (tokenIsValid(token)) {
        headers['Authorization']= 'Bearer '+token
    }
    i.headers = headers
    const r = fetch(url, i)

    r.then((response) => {
        if(response.ok && response.headers.has('x-access-token')) {
            token = response.headers.get('x-access-token')
        }
    })

    return r
}

export async function logout(): Promise<Response> {
    token = null
    return fetch("/api/auth/logout", {method: "POST"})
}

function tokenIsValid(token: string|null|undefined): boolean {
    if (!token){
        return false;
    }
    const decoded = jwtDecode(token)
    const exp = decoded.exp || 0
    return (exp*1000 + 5000) > Date.now()
}
