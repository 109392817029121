import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next)
  .init({
    fallbackLng: 'pl',
    debug: true,
    resources:{
        pl: {
            translation: {
                "Don't have an account?": "Nie masz konta?",
                "Already have an account?": "Masz już konto?",
                "Sign up": "Zarejestruj",
                "Log in": "Zaloguj",
                "Email": "Email",
                "Username": "Nazwa użytkownika",
                "Password": "Hasło",
                "Logout": "Wyloguj"
            }
        }
    }
  });

export default i18n;
