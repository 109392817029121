import { ReactNode, useState } from "react"
import { Card, Divider, List, Modal, Popover, Table, TableColumnsType } from "antd"
import { InfoCircleOutlined, HolderOutlined } from "@ant-design/icons";

import { CSS } from "@dnd-kit/utilities";
import { DndContext, PointerSensor, useDraggable, useDroppable, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { Event, GamePreferences, GameProposition } from "../../api";

interface GameEntry {
    id: string
    name: string
    thumbnail: string
    points: number[]
    propositions: boolean[]
    pointSum: number
    notes: string[]
    dropOff?: boolean
}

export function EventPreferencesResult(props: { event: Event, onPreferenceChange: (fromId: string, toId: string) => any }): (JSX.Element | null) {
    const [swap, setSwap] = useState<[string, string]|undefined>()
    const gamePlayerCount = 3;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const preferences = (props.event.preferences || []).filter(e => e.playerCount === gamePlayerCount)[0]
    const games: GameEntry[] = (props.event.propositions || []).filter(e => e.playerCount >= gamePlayerCount)
        .map((e) => {
            return {
                id: e.gameId,
                name: e.name,
                thumbnail: e.thumbnail,
                points: new Array(preferences.userPreferences.length),
                propositions: new Array(preferences.userPreferences.length),
                pointSum: 0,
                notes: e.notes,
                dropOff: preferences.dropOfGameIds.indexOf(e.gameId) !== -1            
            }
        })
    const startPoints = 4;
    for (let i = 0; i < preferences.userPreferences.length; i++) {
        const pref = preferences.userPreferences[i]
        for (let idx = 0; idx < pref.ids.length; idx++) {
            const g = games.find(e => e.id === pref.ids[idx])
            if (g) {
                g.points[i] = startPoints - idx
                g.pointSum += startPoints - idx
            }
        }
        if (pref.proposedIds){
            for (const propId of pref.proposedIds) {
                const g = games.find(e => e.id === propId)
                if (g) {
                    g.propositions[i] = true
                }
            }
        }
    }
    games.sort((a, b) => b.pointSum - a.pointSum || a.name.localeCompare(b.name))

    const columns: TableColumnsType<GameEntry> = [
        {
            dataIndex: "name",
            title: "Gra",
            render: (value, record, index): ReactNode => {
                return <GameInfoCell game={record}></GameInfoCell>
            },
        },
        {
            title: "Punkty",
            dataIndex: "pointSum"
        }
    ]
    for (let idx = 0; idx < preferences.userPreferences.length; idx++) {
        columns.push({
            onCell:(record, index) => {
                const border = preferences.userPreferences[idx].myPreferences ? "1px #ddd solid" : undefined;
                const style: React.CSSProperties = {
                    textAlign: "center",
                    borderLeft: border, 
                    borderRight: border
                }
                return {
                    style: Object.assign(style, record.propositions[idx] ? {backgroundColor:"lightblue"}:{}),
                    rowIndex: index,
                    colIndex: idx,
                    gameId: record.id,
                    points: record.points[idx]
                }
            },
            render: (value, record, index): any => {
                return (
                    <GamePreferenceCell colIdx={idx} rowIdx={index} preferences={preferences} game={record}/>
                )
            }
        })
    }
    return (
        <Card style={{ width: "100%" }}>
            <h1>Wyniki</h1>
            <Divider />

            <DndContext
                sensors={sensors}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={(evt)=>{
                    const fromId = evt.active.data.current ? evt.active.data.current.gameId : null
                    const fromPoints = evt.active.data.current ? evt.active.data.current.points : null
                    const toId = evt.over?.data.current ? evt.over.data.current.gameId : null
                    const toPoints = evt.over?.data.current ? evt.over.data.current.points : null
                    if (!toPoints || fromPoints > toPoints) {
                        setSwap([fromId, toId])
                    }
                }}
            >
                <Table
                    pagination={false}
                    showHeader={true}
                    dataSource={games}
                    columns={columns}
                    rowKey={"id"}
                    components={{
                        body: {
                            cell: MyCell
                        }
                    }}
                />
            </DndContext>
            <Modal
                open={swap !== undefined}
                onCancel={() => setSwap(undefined)}
                onOk={() => {
                    if (swap) {props.onPreferenceChange(swap[0], swap[1])}
                    setSwap(undefined)
                }}
                destroyOnClose={true}
                title="Czy jesteś pewny?"
                okText="Tak"
                cancelText="Anuluj"
                width={400}
            >
                <div>Przenosisz swój głos z <GameBox game={props.event.propositions?.find((val) => swap?.[0] === val.gameId)}/> na <GameBox game={props.event.propositions?.find((val) => swap?.[1] === val.gameId)}/></div>
            </Modal>
        </Card>
    )
}

function GameBox(props: {game?: GameProposition}): JSX.Element|null {
    const {game} = props
    if (!game) {return null}
    return (
        <div style={{ display: "flex", alignItems: "center", margin: 5}}>
            {game.thumbnail ? <div style={{ width: 32, height: 32, flexShrink: 0, justifyContent: "center", display: "flex" }}><img src={game.thumbnail} style={{ maxWidth: 32, maxHeight: 32, margin: "auto" }} alt="" /></div> : null}
            <div style={{ display: "inline-flex" }}>&nbsp;<a href={"https://boardgamegeek.com/boardgame/" + game.id}>{game.name}</a></div>
        </div>
    )
}

interface GameInfo {
    id: string
    name: string
    thumbnail: string
    notes?: string[]
    dropOff?: boolean
}

export function GameInfoCell(props: { game: GameInfo }): JSX.Element {
    const game = props.game
    const list =  <List
        size="small"
        dataSource={game.notes || []}
        renderItem={(item: string) => <List.Item>{item}</List.Item>}
    />
    return (
        <div style={{ display: "flex", alignItems: "center", opacity: (game.dropOff ? "70%": undefined), filter: (game.dropOff ? "grayscale(0.5)" : undefined)}}>
            {game.thumbnail ? <div style={{ width: 32, height: 32, flexShrink: 0, justifyContent: "center", display: "flex" }}><img src={game.thumbnail} style={{ maxWidth: 32, maxHeight: 32, margin: "auto" }} alt="" /></div> : null}
            <div style={{ display: "inline-flex" }}>&nbsp;<a href={"https://boardgamegeek.com/boardgame/" + game.id}>{game.name}</a></div>
            
            {game.notes && game.notes.length > 0 ? 
            <Popover content={list} title="Notatki" trigger="click">
                <InfoCircleOutlined style={{marginLeft: 5}}/>
            </Popover>
            : null}
        </div>
    )
}

export function GamePreferenceCell(props: {game: GameEntry, colIdx: number, rowIdx: number, preferences: GamePreferences}): JSX.Element|null {
    const record = props.game
    const {colIdx, preferences} = props
    const dropOff = preferences.dropOfGameIds.indexOf(record.id) !== -1
    const myPreferences = preferences.userPreferences[colIdx].myPreferences
    const draggable = dropOff && myPreferences

    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
        id: "pref_" + record.id + "_" + colIdx,
        disabled: !draggable,
        data: {
            gameId: record.id,
            points: record.points[colIdx]
        }
    });

    if (!record.points[colIdx]) {
        return null
    }

    const style: React.CSSProperties = {
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.7 : 1,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        zIndex: isDragging ? 2 : 1,
        cursor: draggable ? "ns-resize" : undefined,
      };
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {record.points[colIdx]}
            { !(dropOff && preferences.userPreferences[colIdx].myPreferences && record.points[colIdx]) ? undefined :
                <span style={{display: "inline-flex", alignContent: "center", flexWrap: "wrap"}}><HolderOutlined/></span>
            }
        </div>
    )
}

function MyCell(props: any) {
    const {style, rowIndex, colIndex, gameId, points, ...propsRest} = props
    const { setNodeRef, isOver, active } = useDroppable({ 
        id: "drop_"+gameId+"_"+colIndex, 
        data: {gameId, points} 
    })
    const activePoints = active?.data?.current ? active.data.current.points as number : -1
    const droppable = isOver && (!points || activePoints > points)
    return <td 
        ref={setNodeRef}
        style={Object.assign({}, style, droppable ? {backgroundColor: "lightgreen"} : {})}
        {...propsRest}
    />
}
