import { useContext, useEffect, useState } from "react"
import { GameSelect } from "./GameSelect"
import { Button, Card } from "antd"
import { EventPreferencesForm } from "./eventView/EventPreferencesForm"
import { EventPreferencesResult } from "./eventView/EventPreferencesResult"
import { WaitForOthers } from "./eventView/WaitForOthers"
import { BoardGameSearchEntry, Event, PropositionRule } from "../api"
import { CurrentUserContext, useHttp } from "../App"

enum EventStatus {
    Propositions = "propositions",
    Preferences = "preferences",
    Done = "done",
    Wait = "wait",
}

export function EventView(props: { eventId: string }) {
    const [event, setEvent] = useState<Event | undefined>(undefined)
    const {currentUser} = useContext(CurrentUserContext)
    const httpFetch = useHttp()
    const fetchEvent = () => {
        httpFetch(`/api/event/${props.eventId}`).then((resp: any) => {
            resp.json().then((data: Event) => {
                setEvent(data)
            })
        })
    }
    const changePreferences = async (fromId: string, toId: string) => {
        httpFetch(`/api/event/${props.eventId}/preferences`, {
            method: "PATCH",
            body: JSON.stringify({ fromId, toId, playerCount: 3 }),
        }).then(() => { fetchEvent() })
    }
    useEffect(fetchEvent, [props.eventId, currentUser, httpFetch])

    if (event && event.status === EventStatus.Wait) {
        return (<WaitForOthers event={event} />)
    } else if (event && event.status === EventStatus.Propositions && event.propositionRules !== undefined) {
        return (<EventPropositionsForm eventId={event.id} propositionRules={event.propositionRules} onSend={fetchEvent} />)
    } else if (event && event.status === EventStatus.Preferences && event.propositions !== undefined) {
        return (<EventPreferencesForm eventId={event.id} propositions={event.propositions} onSend={fetchEvent} />)
    }
    else if (event && event.status === EventStatus.Done && event.preferences !== undefined) {
        return (<EventPreferencesResult event={event} onPreferenceChange={changePreferences}/>)
    }
    return (
        null
    )
}

function EventPropositionsForm(props: { eventId: string, propositionRules: PropositionRule[], onSend: () => any }): (JSX.Element | null) {
    const [games, setGames] = useState([] as BoardGameSearchEntry[])
    const [notes, setNotes] = useState([] as string[])
    const [sending, setSending] = useState(false)
    const httpFetch = useHttp()
    const playerCounts: number[] = []
    for (const r of props.propositionRules) {
        playerCounts.push(...(new Array(r.gameCount).fill(r.minPlayerCount)))
    }
    playerCounts.sort().reverse()

    const sendPropositions = () => {
        if (sending) {
            return;
        }
        setSending(true)
        httpFetch(`/api/event/${props.eventId}/propositions`, {
            method: "POST",
            body: JSON.stringify(games.map((g: any, idx: number) => ({ id: g.id, name: g.name, playerCount: playerCounts[idx], note: notes[idx] })))
        }).then(() => { setSending(false); props.onSend() })
    }
    const setGame = (idx: number): ((game: any) => any) => {
        return (game: any) => {
            const copy = ([] as BoardGameSearchEntry[]).concat(games)
            copy[idx] = game
            setGames(copy)
        }
    }
    const setGameNote = (idx: number): ((note: string) => any) => {
        return (note: string) => {
            const copy = ([] as string[]).concat(notes)
            copy[idx] = note
            setNotes(copy)
        }
    }

    const selects: JSX.Element[] = [];
    for (let i = 0; i < playerCounts.length; i++) {
        const p = playerCounts[i]
        selects.push(
            <div key={i} style={{ margin: 6 }}>
                <label>Gra dla {p}+ graczy </label>
                <GameSelect onChange={setGame(i)} onNoteChange={setGameNote(i)}/>
            </div>
        )
    }
    return (
        <Card>
            <h1>Gry które możesz wziąć w przyszłym tygodniu</h1>
            {selects}
            <Button type="primary" onClick={sendPropositions} size="large" disabled={sending}>Wyślij</Button>
        </Card>
    )
}



