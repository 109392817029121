import { Card, Divider } from "antd"
import { Event } from "../../api";



export function WaitForOthers(props: { event: Event }): (JSX.Element | null) {
    return (
        <Card style={{ width: "100%" }}>
            <h1>Poczekaj na innych</h1>
            <Divider />
            <h3>Status {props.event.entryCount} / {props.event.participantCount}</h3>
        </Card>
    )
}
